<template>
  <v-card color="white" class="history-slider drop-shadow">
    <v-card-text>
      <v-layout row>
        <v-flex
          shrink
          class="slider-label border-radius-8 cursor-pointer padding-a-1"
          :class="`date-time-label__${showStartDateTimeSelector ? 'on' : 'off'}`"
        >
          <span
            class="font-weight-bold"
            @click="clickStartLabel"
            @mousewheel="
              handleMouseWheel([
                historicalMarkersRange[0] + $event.deltaY,
                historicalMarkersRange[1],
              ])
            "
          >
            <p class="margin-a-0">{{ displayStartDate }}</p>
            <p class="white-space-nowrap margin-a-0">{{ displayStartTime }}</p>
          </span>
          <ReservationTrackingMapDateTimeSelector
            v-show="showStartDateTimeSelector"
            class="date-time-selector"
            :date-time="selectedStartDateTime"
            :min-date-time="historicalMarkersRange[0]"
            :max-date-time="selectedEndDateTime"
            :display-time-zone="$attrs.zone"
            reset-type="min"
            @update-date-time="updateSelectedStartDateTime"
          />
        </v-flex>

        <v-flex class="px-3">
          <VueSlider
            strict
            always-dirty
            :min="selectedStartDateTime"
            :max="selectedEndDateTime"
            :value="Math.round(vehicleTime)"
            :marks="marks"
            tooltip="always"
            class="mt-4 mb-4"
            @change="$emit('update-vehicle-time', $event)"
          >
            <template #tooltip="{ value }">
              <div class="thumb-label">
                <div class="thumb-label__pointer" style="left: 41%" />
                {{
                  DateTime.fromSeconds(value, dateDisplayOptions).toFormat(
                    'h:mma ZZZZ'
                  )
                }}
              </div>
            </template>
            <template #step="{ active }">
              <div
                :class="`custom-step ${active ? 'custom-step__active' : ''}`"
              ></div>
            </template>
          </VueSlider>
        </v-flex>

        <v-flex
          shrink
          class="slider-label border-radius-8 cursor-pointer padding-a-1"
          :class="`date-time-label__${showEndDateTimeSelector ? 'on' : 'off'}`"
        >
          <span
            class="font-weight-bold"
            @click="clickEndLabel"
            @mousewheel="
              handleMouseWheel([
                historicalMarkersRange[0],
                historicalMarkersRange[1] + $event.deltaY,
              ])
            "
          >
            <p class="margin-a-0">{{ displayEndDate }}</p>
            <p class="white-space-nowrap margin-a-0">{{ displayEndTime }}</p>
          </span>
          <ReservationTrackingMapDateTimeSelector
            v-show="showEndDateTimeSelector"
            class="date-time-selector date-time-max-selector"
            :date-time="selectedEndDateTime"
            :min-date-time="selectedStartDateTime"
            :max-date-time="historicalMarkersRange[1]"
            :display-time-zone="$attrs.zone"
            reset-type="max"
            @update-date-time="updateSelectedEndDateTime"
          />
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import { DateTime } from 'luxon'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import ReservationTrackingMapDateTimeSelector from '@/components/ReservationTrackingMapDateTimeSelector.vue'

export default {
  components: {
    VueSlider,
    ReservationTrackingMapDateTimeSelector,
  },
  model: {
    prop: 'historicalMarkersRange',
  },
  props: {
    historicalMarkersRange: {
      type: Array,
      required: true,
    },
    historicalData: {
      type: Array,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    vehicleTime: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    DateTime,
    showStartDateTimeSelector: false,
    showEndDateTimeSelector: false,
    selectedStartDateTime: -1,
    selectedEndDateTime: -1,
  }),
  computed: {
    displayStartDate() {
      return DateTime.fromSeconds(
        this.selectedStartDateTime,
        this.dateDisplayOptions
      ).toFormat('L/d/yy')
    },
    displayStartTime() {
      return DateTime.fromSeconds(
        this.selectedStartDateTime,
        this.dateDisplayOptions
      ).toFormat('h:mma ZZZZ')
    },
    displayEndDate() {
      return DateTime.fromSeconds(
        this.selectedEndDateTime,
        this.dateDisplayOptions
      ).toFormat('L/d/yy')
    },
    displayEndTime() {
      return DateTime.fromSeconds(
        this.selectedEndDateTime,
        this.dateDisplayOptions
      ).toFormat('h:mma ZZZZ')
    },
    marks() {
      const marks = {}
      let value = this.selectedStartDateTime
      let increment = 60
      while (value < this.selectedEndDateTime) {
        if (
          DateTime.fromSeconds(value, this.dateDisplayOptions).hour === 0 &&
          DateTime.fromSeconds(value, this.dateDisplayOptions).minute === 0
        ) {
          marks[value] = {
            label: DateTime.fromSeconds(value).toFormat('MM/dd'),
            labelStyle: {
              fontSize: '12px',
            },
          }
          if (increment === 60) {
            increment = 86400
          }
        }
        value += increment
      }
      return marks
    },
    sliderStartDate() {
      return Math.max(
        Math.min(
          ...this.historicalData.map((hd) =>
            DateTime.fromISO(hd.receivedDate || hd.reportedOn).toSeconds()
          )
        ),
        DateTime.fromISO(this.startDate).minus({ hours: 1 }).toSeconds()
      )
    },
    sliderEndDate() {
      return Math.min(
        Math.max(
          ...this.historicalData.map(
            (hd) =>
              DateTime.fromISO(hd.receivedDate || hd.reportedOn).toSeconds(),
            DateTime.fromISO(this.endDate).plus({ hours: 1 }).toSeconds()
          )
        ),
        DateTime.local().toSeconds()
      )
    },
    dateDisplayOptions() {
      return {
        zone: this.$attrs.zone,
      }
    },
  },
  watch: {
    historicalMarkersRange: {
      deep: true,
      handler() {
        this.selectedStartDateTime = this.historicalMarkersRange[0]
        this.selectedEndDateTime = this.historicalMarkersRange[1]
      },
    },
  },
  mounted() {
    this.$emit('update', [this.sliderStartDate, this.sliderEndDate])
    this.selectedStartDateTime = this.historicalMarkersRange[0]
    this.selectedEndDateTime = this.historicalMarkersRange[1]
  },
  beforeDestroy() {
    this.$emit('update', [0, Infinity])
  },
  methods: {
    handleMouseWheel(newHistoricalMarkersRange) {
      this.$emit('update', [
        Math.min(
          Math.max(newHistoricalMarkersRange[0], this.sliderStartDate),
          this.historicalMarkersRange[1]
        ),
        Math.max(
          Math.min(newHistoricalMarkersRange[1], this.sliderEndDate),
          this.historicalMarkersRange[0]
        ),
      ])
    },
    updateSelectedStartDateTime(newStartDateTime) {
      this.selectedStartDateTime = newStartDateTime
      this.$emit('update-slider-time', this.selectedStartDateTime, this.selectedEndDateTime)
      if (this.vehicleTime < this.selectedStartDateTime) {
        this.$emit('update-vehicle-time', this.selectedStartDateTime)
      }
    },
    updateSelectedEndDateTime(newEndDateTime) {
      this.selectedEndDateTime = newEndDateTime
      this.$emit('update-slider-time', this.selectedStartDateTime, this.selectedEndDateTime)
      if (this.vehicleTime > this.selectedEndDateTime) {
        this.$emit('update-vehicle-time', this.selectedEndDateTime)
      }
    },
    clickStartLabel() {
      this.showStartDateTimeSelector = !this.showStartDateTimeSelector
    },
    clickEndLabel() {
      this.showEndDateTimeSelector = !this.showEndDateTimeSelector
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-shadow {
  box-shadow: 0px 3px 4px rgba($black-base, 0.18) !important;
}
.date-time-label {
  &__on {
    width: min-content !important;
    background-color: #cde1ee !important;
  }
  &__off {
    width: min-content !important;
    background-color: $background-hover;
    &:hover {
      background-color: #edf4fa !important;
    }
  }
}
.custom-step {
  height: 16px;
  width: 2px;
  background-color: $gray-medium-dark;
  position: relative;
  top: -6px;

  &__active {
    background-color: $blue;
  }
}
.history-slider {
  background: white;
  padding: 10px 0px 0px 0px;
  border-radius: 10px;
}
.thumb-label {
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  min-height: 18px;
  min-width: 50px;
  background: $gray-light;
  color: black;
  text-align: center;
  width: max-content;
  &__inner {
    line-height: 18px;
  }
  &__pointer {
    transform: rotateY(0deg) rotate(45deg); /* needs Y at 0 deg to behave properly*/
    height: 15px;
    width: 15px;
    background: $gray-light;
    position: absolute;
    bottom: -5px;
    left: 35%;
    margin: 0px auto;
    z-index: -1;
  }
}
.slider-label {
  width: 90px;
  text-align: center;
  margin: auto 0px;
}
::v-deep .v-slider__thumb-label {
  bottom: 5px;
}

.date-time-selector {
  width: 290px;
  position: absolute;
  bottom: 100px;
}

.date-time-max-selector {
  right: 20px;
}
</style>
