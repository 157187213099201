import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { JourneyOnTimeStatus, JourneyStatus, JourneyStatusResponse, GenericApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getJourneyOnTimeStatus(journeyIds: number[]): Promise<AxiosResponse<GenericApiResult<JourneyOnTimeStatus[]>>> {
    const url = `https://${baseUrl()}/onTime/calculateOnTimeStatuses`
    return httpService.post(url, journeyIds)
  },
  getStopStatusesForJourneys(journeyIds: number[]): Promise<AxiosResponse<GenericApiResult<JourneyStatus[]>>> {
    const url = `https://${baseUrl()}/v2/tracking/status/stops`
    return httpService.post(url, journeyIds)
  },
  getStopStatusesForJourneysV2(journeyIds: number[]): Promise<AxiosResponse<JourneyStatusResponse>> {
    const url = `https://${baseUrl()}/journeys/stopStatus`
    return httpService.post(url, journeyIds)
  },
}
