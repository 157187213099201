<template>
  <v-card class="selector border-radius-8 drop-shadow">
    <v-date-picker
      v-model="computedDate"
      class="date-picker margin-t-2"
      solo
      flat
      no-title
      :min="computedMinDate"
      :max="computedMaxDate"
    ></v-date-picker>
    <CRInput
      v-model="computedTime"
      type="time"
      class="time-picker margin-b-5 margin-t-3"
    />
    <v-card-actions class="actions">
      <p class="reset-button font-weight-bold margin-b-2" @click="resetSelector">Reset</p>
    </v-card-actions>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'
import CRIcon from '@/cr/components/CRIcon.vue'
import * as logger from '@/utils/logger'

const MIN_RESET_TYPE = 'min'
const MAX_RESET_TYPE = 'max'

export default {
  components: { CRIcon },
  props: {
    dateTime: {
      type: Number,
      required: true,
    },
    minDateTime: {
      type: Number,
      required: true,
    },
    maxDateTime: {
      type: Number,
      required: true,
    },
    displayTimeZone: {
      type: String,
      required: true,
    },
    resetType: {
      type: String,
      default: () => MIN_RESET_TYPE,
    },
  },
  emits: ['update-date-time'],
  computed: {
    computedMinDate() {
      const luxonDateTime = DateTime.fromSeconds(this.minDateTime, {
        zone: this.displayTimeZone,
      })
      return luxonDateTime.toISODate()
    },
    computedMaxDate() {
      const luxonDateTime = DateTime.fromSeconds(this.maxDateTime, {
        zone: this.displayTimeZone,
      })
      return luxonDateTime.toISODate()
    },
    luxonDateTime() {
      const luxonDateTime = DateTime.fromSeconds(this.dateTime, {
        zone: this.displayTimeZone,
      })
      return luxonDateTime
    },
    computedDate: {
      get() {
        return this.luxonDateTime.toISODate()
      },
      set(newDate) {
        const newDateTime = `${newDate}T${this.computedTime}`
        this.$emit('update-date-time', this.limitDateTime(newDateTime))
      },
    },
    computedTime: {
      get() {
        return this.luxonDateTime.toLocaleString({
          hour: 'numeric',
          minute: 'numeric',
          hourCycle: 'h23',
        })
      },
      set(newTime) {
        const newDateTime = `${this.computedDate}T${newTime}`
        this.$emit('update-date-time', this.limitDateTime(newDateTime))
      },
    },
  },
  methods: {
    limitDateTime(dateTimeString) {
      const newDateTimeSeconds = DateTime.fromISO(dateTimeString, {
        zone: this.displayTimeZone,
      }).toSeconds()
      const lowLimitSeconds = Math.max(newDateTimeSeconds, this.minDateTime)
      const highLimitSeconds = Math.min(lowLimitSeconds, this.maxDateTime)
      return highLimitSeconds
    },
    resetSelector() {
      if (this.resetType === MIN_RESET_TYPE) {
        this.$emit('update-date-time', this.minDateTime)
      } else if (this.resetType === MAX_RESET_TYPE) {
        this.$emit('update-date-time', this.maxDateTime)
      } else {
        logger.error(`could not reset, unknown reset type '${this.resetType}'`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.drop-shadow {
  box-shadow: 0px 3px 4px rgba($black-base, 0.18) !important;
}

.selector {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-group {
  margin: auto;
}

.group-button {
  padding-left: 16px;
  padding-right: 16px;
}

.date-picker {
  margin: auto;
  box-shadow: none !important;
}

.time-picker {
  margin: auto;
  width: 250px;
}

.reset-button {
  cursor: pointer;
  color: $primary;
  padding-left: 10px;
  padding-right: 10px;
}

.actions {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
</style>
