<template>
  <v-flex v-if="currentJourneysByTripVehicleGroup.length > 1" class="w-max">
    <v-select
      v-model="selectedJourneysComputed"
      class="reservation-trip-assignment-selector mr-2"
      :items="currentJourneysByTripVehicleGroup"
      :item-text="simulatedTripAssignmentVehicles[journeyVehicle?.vehicleId] !== undefined ? simulatedTripAssignmentVehicles[journeyVehicle?.vehicleId].vehicleName : 'vehicle.vehicleName'"
      item-value="journeyId"
      :color="$vuetify.theme.gray"
      flat
      solo
      hide-details
      multiple
      shrink
      append-icon="keyboard_arrow_down"
    >
      <template
        v-if="areAllVehicleJourneysSelected && selectedJourneys.length > 1"
        #selection="{ index }"
      >
        <span
          v-if="index === 0"
          class="font-weight-bold"
          :style="`color: ${$vuetify.theme.gray}`"
        >
          All
        </span>
      </template>
      <template #prepend-item>
        <v-list-tile style="cursor: pointer" @click="toggleSelectAll">
          <v-checkbox
            hide-details
            class="ma-0 pa-0 no-grow"
            :input-value="areAllVehicleJourneysSelected"
            @click.prevent
          />
          <span style="flex: 14; padding-left: 10px">All</span>
        </v-list-tile>
      </template>
      <template #prepend-inner>
        <CRIcon
          view-box="4 0 28 32"
          :width="18"
          :height="18"
          color="grayMediumDark"
        >
          vehicles
        </CRIcon>
      </template>
      <template #item="{ item, attrs }">
        <v-checkbox
          v-bind="attrs"
          hide-details
          class="ml-3 mt-0 no-grow"
          :input-value="isSelected(item.journeyId)"
          @click.prevent
        />
        <span style="padding-left: 10px">{{ getJourneyVehicleName(item.vehicle) }}</span>
      </template>
    </v-select>
  </v-flex>
  <v-flex
    v-else-if="currentJourneysByTripVehicleGroup.length === 1"
    shrink
    class="static-info-wrapper d-flex align-center w-max"
  >
    <p class="static-info">
      {{ getJourneyVehicleName(currentJourneysByTripVehicleGroup[0].vehicle) }}
    </p>
  </v-flex>
</template>

<script>
export default {
  props: {
    currentJourneysByTripVehicleGroup: { type: Array, required: true },
    selectedJourneys: { type: Array, required: true },
    simulatedTripAssignmentVehicles: { type: Object, required: true },
  },
  emits: ['update-selected-journeys'],
  data() {
    return {}
  },
  computed: {
    selectedJourneysComputed: {
      get() {
        return this.selectedJourneys
      },
      set(newSelectedJourneyIds) {
        const newSelectedJourneys = this.currentJourneysByTripVehicleGroup.filter(
          (vehicleJourney) =>
            newSelectedJourneyIds.includes(vehicleJourney.journeyId)
        )
        this.updateSelectedJourneys(newSelectedJourneys)
      },
    },
    areAllVehicleJourneysSelected() {
      return (
        this.selectedJourneys.length ===
        this.currentJourneysByTripVehicleGroup.length
      )
    },
  },
  methods: {
    isSelected(journeyId) {
      return this.selectedJourneys.some((j) => j.journeyId === journeyId)
    },
    updateSelectedJourneys(newSelectedJourneys) {
      this.$emit('update-selected-journeys', newSelectedJourneys)
    },
    toggleSelectAll() {
      if (this.areAllVehicleJourneysSelected) {
        this.updateSelectedJourneys([])
      } else {
        this.updateSelectedJourneys(this.currentJourneysByTripVehicleGroup)
      }
    },
    getJourneyVehicleName(journeyVehicle) {
      const simulatedVehicle = this.simulatedTripAssignmentVehicles[journeyVehicle?.vehicleId]
      if (simulatedVehicle) {
        return simulatedVehicle.vehicleName
      }
      return journeyVehicle?.vehicleName
    }
  },
}
</script>

<style lang="scss" scoped>
.reservation-trip-assignment-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $gray;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
      background-color: $background-hover !important;

      input,
      select {
        width: 40px !important;
      }
    }
  }
}

.coachrail .theme--light.v-select ::v-deep .v-select__slot {
  max-width: 95%;
}

.coachrail .theme--light.v-select ::v-deep .v-select__selections {
  color: $gray !important;
  font-weight: 700;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;

  input {
    display: none;
  }
}

.static-info-wrapper {
  height: 40px;
  background: $background-hover;
  border-radius: 4px;

  &:first-child {
    margin-right: 8px;
  }

  &:not(:first-child) {
    padding-left: 0px !important;
    margin-left: 7px;
  }
}

.static-info {
  max-width: 100%;
  height: fit-content;
  color: $gray;
  font-weight: bold;
  padding: 0 12px 0 12px;
  margin-bottom: 0px !important;
}
</style>
