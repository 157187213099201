<template>
  <v-flex v-if="referredProvidersComputed.length > 1" class="w-max">
    <v-select
      v-model="selectedProvidersComputed"
      class="reservation-trip-assignment-selector mr-2"
      :items="referredProvidersComputed"
      item-text="computedName"
      flat
      solo
      multiple
      :color="$vuetify.theme.gray"
      :background-color="$vuetify.theme.backgroundColor"
      shrink
      return-object
      hide-details
      append-icon="keyboard_arrow_down"
      @change="updateSelectedProviders"
    >
      <template v-if="areAllProvidersSelected" #selection="{ index }">
        <span
          v-if="index === 0"
          class="font-weight-bold"
          :style="`color: ${$vuetify.theme.gray}`"
        >
          All
        </span>
      </template>
      <template #prepend-item>
        <v-list-tile style="cursor: pointer" @click="toggleSelectAll">
          <v-checkbox
            hide-details
            class="ma-0 pa-0"
            style="width: 100%:"
            :input-value="areAllProvidersSelected"
            @click.prevent
          />
          <span style="flex: 14">All</span>
        </v-list-tile>
      </template>
      <template #item="{ item, attrs }">
        <v-checkbox
          v-bind="attrs"
          hide-details
          style="margin-left: 10px; margin-top: 0px; flex: 0"
          :input-value="isSelected(item.companyId)"
          @click.prevent
        />
        <span style="padding-left: 10px">{{ item.computedName }}</span>
      </template>
    </v-select>
  </v-flex>
  <v-flex
    v-else-if="referredProvidersComputed.length === 1"
    shrink
    class="static-info-wrapper d-flex align-center w-max"
  >
    <p class="static-info">
      {{ referredProvidersComputed[0].computedName }}
    </p>
  </v-flex>
</template>

<script>
export default {
  props: {
    referredProviders: { type: Array, required: true },
    selectedProviders: { type: Array, required: true },
  },
  emits: ['update-selected-providers'],
  data() {
    return {}
  },
  computed: {
    selectedProvidersComputed: {
      get() {
        return this.selectedProviders
      },
      set(newSelectedProviders) {
        this.updateSelectedProviders(newSelectedProviders)
      },
    },
    referredProvidersComputed() {
      return this.referredProviders.filter(
        (provider) => provider.computedName !== 'All'
      )
    },
    areAllProvidersSelected() {
      return (
        this.selectedProviders &&
        this.selectedProviders.length === this.referredProvidersComputed.length
      )
    },
  },
  methods: {
    isSelected(companyId) {
      return this.selectedProvidersComputed.some(
        (p) => p.companyId === companyId
      )
    },
    updateSelectedProviders(newSelectedProviders) {
      this.$emit('update-selected-providers', newSelectedProviders)
    },
    toggleSelectAll() {
      if (this.areAllProvidersSelected) {
        this.updateSelectedProviders([])
      } else {
        this.updateSelectedProviders(this.referredProvidersComputed)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-trip-assignment-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $gray;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
      background-color: $background-hover !important;

      input,
      select {
        width: 40px !important;
      }
    }
  }
}

.coachrail .theme--light.v-select ::v-deep .v-select__selections {
  color: $gray !important;
  font-weight: 700;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;

  input {
    display: none;
  }
}

.static-info-wrapper {
  height: 40px;
  background: $background-hover;
  border-radius: 4px;

  &:first-child {
    margin-right: 8px;
  }

  &:not(:first-child) {
    padding-left: 0px !important;
    margin-left: 7px;
  }
}

.static-info {
  max-width: 100%;
  height: fit-content;
  color: $gray;
  font-weight: bold;
  padding: 0 12px 0 12px;
  margin-bottom: 0px !important;
}
</style>
