<template>
  <svg
    width="1000px"
    height="1000px"
    viewBox="0 0 1000 1000"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="linearGradient-1"
        x1="49.9644712%"
        y1="98.6616025%"
        x2="50.0657049%"
        y2="-47.900147%"
      >
        <stop stop-color="#5C5C5C" offset="0%"></stop>
        <stop stop-color="#979797" offset="10.42%"></stop>
      </linearGradient>
      <linearGradient
        id="linearGradient-2"
        x1="49.9996089%"
        y1="105.263885%"
        x2="49.9996089%"
        y2="25.884696%"
      >
        <stop stop-color="#000000" offset="31.11%"></stop>
        <stop stop-color="#37475E" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        id="linearGradient-3"
        x1="-0.00411231654%"
        y1="49.9985445%"
        x2="99.9948259%"
        y2="49.9985445%"
      >
        <stop stop-color="#000000" offset="31.11%"></stop>
        <stop stop-color="#37475E" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <circle
        cx="500"
        cy="500"
        r="500"
        :style="`fill: ${color}; opacity: ${opacity}`"
      />
      <g
        id="0"
        :transform="`translate(19 343) rotate(${heading + 90} 489 152)`"
      >
        <polygon
          id="Path"
          fill="url(#linearGradient-1)"
          points="914.09 292.72 957.59 225.79 61.3 235.05 19.76 303.17 852.86 307.41"
        ></polygon>
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          x="66.81"
          y="233.98"
          width="9.83"
          height="5.62"
        ></rect>
        <path
          id="Path"
          d="M57.03,1.26 L0.3,85.76 C0.3,85.76 4.63,288.61 19.41,303.41 L71.39,234.34 L57.03,1.26 Z"
          fill="#FFFFFF"
        ></path>
        <path
          id="Path"
          d="M957.59,225.79 L79.93,234.91 C71.51,235 63.67,239.42 59.28,246.61 L37.21,279.97 C43.43,280.58 91.88,285.02 105.92,264.95 L926.59,273.48 L957.59,225.79 Z"
          fill="url(#linearGradient-2)"
        ></path>
        <path
          id="Path"
          d="M921.71,4.42 C934,4.78 944.61,13.13 947.85,24.99 C956.2,55.57 962.46,180.11 960.59,215.26 C959.82,229.73 947.8,241.03 933.31,240.91 L60.7,235.4 C60.34,235.62 57.73,0.81 56.98,0.81 L921.71,4.42 Z"
          fill="#FFFFFF"
        ></path>
        <path
          id="Path"
          d="M57.05,5.96 L20.5,59.41 C16.14,67.31 14.11,76.28 14.64,85.28 L26.05,271.14 C26.35,276.32 33.23,277.91 35.77,273.39 C44.58,257.74 60.64,231.49 66.76,231.49 C67.47,204.43 57.05,5.96 57.05,5.96 Z"
          fill="url(#linearGradient-3)"
        ></path>
        <ellipse
          id="Oval"
          fill="#110C0A"
          cx="156.71"
          cy="304.12"
          rx="38.1"
          ry="16.09"
        ></ellipse>
        <ellipse
          id="Oval"
          fill="#110C0A"
          cx="721.36"
          cy="304.12"
          rx="38.1"
          ry="16.09"
        ></ellipse>
        <ellipse
          id="Oval"
          fill="#110C0A"
          cx="801.83"
          cy="304.12"
          rx="38.1"
          ry="16.09"
        ></ellipse>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'BusIcon',
  props: {
    heading: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'red',
    },
    opacity: {
      type: Number,
      default: 0.3,
    },
  },
}
</script>
