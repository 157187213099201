<template>
  <v-flex class="w-max">
    <CRSelect
      v-model="selectedTripVehicleGroupIdComputed"
      class="reservation-trip-assignment-selector mr-2"
      :items="currentTripVehicleGroups"
      item-text="name"
      item-value="tripVehicleGroupId"
      flat
      solo
      hide-details
      color="$vuetify.theme.gray"
      background-color="$vuetify.theme.backgroundColor"
      shrink
    />
  </v-flex>
</template>

<script>
export default {
  props: {
    currentTripVehicleGroups: { type: Array, required: true },
    selectedTripVehicleGroupId: { type: Number, required: true },
  },
  emits: ['update-selected-trip-vehicle-group-ids'],
  data() {
    return {}
  },
  computed: {
    selectedTripVehicleGroupIdComputed: {
      get() {
        return this.selectedTripVehicleGroupId
      },
      set(newSelectedTripVehicleGroupId) {
        this.updateSelectedTripVehicleGroupId(newSelectedTripVehicleGroupId)
      },
    },
  },
  methods: {
    updateSelectedTripVehicleGroupId(newSelectedTripVehicleGroupId) {
      this.$emit(
        'update-selected-trip-vehicle-group-ids',
        newSelectedTripVehicleGroupId
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-trip-assignment-selector ::v-deep {
  overflow: hidden;

  label,
  .v-icon {
    color: $gray;
  }

  .theme--light.v-select .v-select__selections {
    color: $gray !important;
    font-weight: 700;
  }

  .v-input__control {
    min-height: 40px !important;
    > .v-input__slot {
      max-height: 40px !important;
      min-height: 40px !important;
      background-color: $background-hover !important;

      input,
      select {
        width: 40px !important;
      }
    }
  }
}

.static-info-wrapper {
  height: 40px;
  background: $background-hover;
  border-radius: 4px;

  &:first-child {
    margin-right: 8px;
  }

  &:not(:first-child) {
    padding-left: 0px !important;
    margin-left: 7px;
  }
}

.static-info {
  max-width: 100%;
  height: fit-content;
  color: $gray;
  font-weight: bold;
  padding: 0 12px 0 12px;
  margin-bottom: 0px !important;
}
</style>
