<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    viewBox="0 0 56 56"
    aria-labelledby="box"
    role="presentation"
    class="cr-icon"
  >
    <title id="box" lang="en">box icon</title>
    <g :fill="generatedStopIconColor">
      <path
        d="M17.0354906,0 C7.62704894,0 0,7.7007023 0,17.2 C0,17.5630831 0.0213502218,18.1417302 0.0640506654,18.9359413 C0.065551237,18.9638513 0.0678614188,19.0063153 0.0709812109,19.0633333 C2.5,32.5 17.0354906,43 17.0354906,43 C17.0354906,43 31.5,32.5 33.9290188,19.06 L33.9290188,18.8483333 C34,18.275 34,17.7733333 34,17.2 C34,7.72861964 26.4162005,0.0394640845 17.0354906,0 Z"
      ></path>
      <text
        v-if="iconText"
        :x="xPosition"
        y="24"
        fill="white"
        class="font-weight-bold"
        style="font-size: 14px; font-family: sans-serif"
      >
        {{ iconText }}
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'StopIcon',
  props: {
    status: { type: String, default: '' },
    number: { type: Number, default: 0 },
    repeatedStop: { type: Boolean, default: false },
  },
  computed: {
    generatedStopIconColor() {
      if (this.status === 'EN ROUTE') {
        return '#3b9cf1'
      } else if (this.status === 'COMPLETED') {
        return '#7cd074'
      } else if (this.status === 'GARAGE') {
        return '#9e9e9e'
      } else {
        return '#3e4351'
      }
    },
    xPosition() {
      if (this.status === 'GARAGE') {
        return 11
      }
      if (this.number > 9) {
        return this.repeatedStop ? 7.5 : 8.5
      } else {
        return this.repeatedStop ? 12 : 13
      }
    },
    iconText() {
      if (this.status === 'GARAGE') {
        return 'G'
      }
      if (this.number) {
        return this.repeatedStop ? `${this.number}*` : this.number
      }
      return null
    },
  },
}
</script>
<style lang="scss">
.overlay {
  fill: white;
  font-size: 11px;
}
.primary {
  fill: $primary;
}
.green {
  fill: $green;
}
.grayDark {
  fill: $gray-dark;
}
</style>
