var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"history-slider drop-shadow",attrs:{"color":"white"}},[_c('v-card-text',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"slider-label border-radius-8 cursor-pointer padding-a-1",class:`date-time-label__${_vm.showStartDateTimeSelector ? 'on' : 'off'}`,attrs:{"shrink":""}},[_c('span',{staticClass:"font-weight-bold",on:{"click":_vm.clickStartLabel,"mousewheel":function($event){return _vm.handleMouseWheel([
              _vm.historicalMarkersRange[0] + $event.deltaY,
              _vm.historicalMarkersRange[1],
            ])}}},[_c('p',{staticClass:"margin-a-0"},[_vm._v(_vm._s(_vm.displayStartDate))]),_c('p',{staticClass:"white-space-nowrap margin-a-0"},[_vm._v(_vm._s(_vm.displayStartTime))])]),_c('ReservationTrackingMapDateTimeSelector',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStartDateTimeSelector),expression:"showStartDateTimeSelector"}],staticClass:"date-time-selector",attrs:{"date-time":_vm.selectedStartDateTime,"min-date-time":_vm.historicalMarkersRange[0],"max-date-time":_vm.selectedEndDateTime,"display-time-zone":_vm.$attrs.zone,"reset-type":"min"},on:{"update-date-time":_vm.updateSelectedStartDateTime}})],1),_c('v-flex',{staticClass:"px-3"},[_c('VueSlider',{staticClass:"mt-4 mb-4",attrs:{"strict":"","always-dirty":"","min":_vm.selectedStartDateTime,"max":_vm.selectedEndDateTime,"value":Math.round(_vm.vehicleTime),"marks":_vm.marks,"tooltip":"always"},on:{"change":function($event){return _vm.$emit('update-vehicle-time', $event)}},scopedSlots:_vm._u([{key:"tooltip",fn:function({ value }){return [_c('div',{staticClass:"thumb-label"},[_c('div',{staticClass:"thumb-label__pointer",staticStyle:{"left":"41%"}}),_vm._v(" "+_vm._s(_vm.DateTime.fromSeconds(value, _vm.dateDisplayOptions).toFormat( 'h:mma ZZZZ' ))+" ")])]}},{key:"step",fn:function({ active }){return [_c('div',{class:`custom-step ${active ? 'custom-step__active' : ''}`})]}}])})],1),_c('v-flex',{staticClass:"slider-label border-radius-8 cursor-pointer padding-a-1",class:`date-time-label__${_vm.showEndDateTimeSelector ? 'on' : 'off'}`,attrs:{"shrink":""}},[_c('span',{staticClass:"font-weight-bold",on:{"click":_vm.clickEndLabel,"mousewheel":function($event){return _vm.handleMouseWheel([
              _vm.historicalMarkersRange[0],
              _vm.historicalMarkersRange[1] + $event.deltaY,
            ])}}},[_c('p',{staticClass:"margin-a-0"},[_vm._v(_vm._s(_vm.displayEndDate))]),_c('p',{staticClass:"white-space-nowrap margin-a-0"},[_vm._v(_vm._s(_vm.displayEndTime))])]),_c('ReservationTrackingMapDateTimeSelector',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEndDateTimeSelector),expression:"showEndDateTimeSelector"}],staticClass:"date-time-selector date-time-max-selector",attrs:{"date-time":_vm.selectedEndDateTime,"min-date-time":_vm.selectedStartDateTime,"max-date-time":_vm.historicalMarkersRange[1],"display-time-zone":_vm.$attrs.zone,"reset-type":"max"},on:{"update-date-time":_vm.updateSelectedEndDateTime}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }